<template>
    <div>
        <card-header title="Edit Registration Note" icon="fa-pencil"/>

        <card-body>
            <trip-note-form :note="note"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteNote"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Note?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit" :disabled="!note.subject"><i class="fas fa-check mr-2"></i>Save Note</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {client as http} from "../../http_client";
    import TripNoteForm from "@/cards/trips/NoteForm";

    export default {
        components: {TripNoteForm, ContainerListItem, SubheaderListItem, CardList, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                note: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadNote: function () {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/notes/' + this.props.noteId, {force: true}).then(response => {
                    this.note = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/registrations/' + this.props.registrationId + '/notes/' + this.props.noteId, {
                    subject: this.note.subject,
                    note: this.note.note
                }, {force: true}).then(response => {
                    this.$reloadCard('reg-notes', {registrationId: this.props.registrationId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteNote() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/registrations/' + this.props.registrationId + '/notes/' + this.props.noteId).then(response => {
                        this.$reloadCard('reg-notes', {registrationId: this.props.registrationId});
                        this.$reloadCard('registration', {registrationId: this.props.registrationId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.noteId': function () {
                this.dirty = null;
                this.loadNote();
            },
            'note': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this note? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadNote();
        }
    };
</script>

<style scoped>

</style>
